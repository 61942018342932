<template>
  <g id="coal">
    <line :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2" stroke="#f00" stroke-width="7" style="cursor: move;"/>
    <polygon :points="left_part_points" fill="#999" stroke="#333" id="left_coal" />
    <polygon :points="right_part_points" fill="#999" stroke="#333" id="right_coal" />
  </g>
</template>

<script>
import Snap from "snapsvg-cjs";

export default {
  props: {
    params: Object,
    input_data: Object,
    results: Object,
    pixcel: Number,
    border_thickness: Number,
    row: Number,
  },
  data: function () {
    return {
      left_part_points: "",
      right_part_points: "",
      line: {},
    }
  },
  watch: { 
    input_data: function(){
      this.draw_svg()
    },
    row: function(){
      this.draw_svg()
    },
  },
  mounted(){
    this.draw_svg()
  },
  methods: {
    draw_svg(){
      let
        svg = Snap("#coal"),
        coal_params = this.params["violation_params"][this.row],
        move = function(dx) {
          this.attr({
            transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [dx, 0]
          });
        },
        start = function() {this.data('origTransform', this.transform().local )}
      
        if(!coal_params) return

      let
        // точки левой части
        left_part = {
          top_left: {x: coal_params.coal_left_part[0].x * this.pixcel, y: coal_params.coal_left_part[0].y * this.pixcel + this.border_thickness},
          top_right: {x: coal_params.coal_left_part[1].x * this.pixcel, y: coal_params.coal_left_part[1].y * this.pixcel + this.border_thickness},
          bottom_right: {x: coal_params.coal_left_part[2].x * this.pixcel, y: coal_params.coal_left_part[2].y * this.pixcel + this.border_thickness},
          bottom_left: {x: coal_params.coal_left_part[3].x * this.pixcel, y: coal_params.coal_left_part[3].y * this.pixcel + this.border_thickness},
        },

        addition_offest = window.innerWidth,

        // точки правой части
        right_part = {
          top_left: {x: coal_params.coal_right_part[0].x * this.pixcel, y: coal_params.coal_right_part[0].y * this.pixcel + this.border_thickness},
          top_right: {x: coal_params.coal_right_part[1].x * this.pixcel + addition_offest, y: coal_params.coal_right_part[1].y * this.pixcel + this.border_thickness},
          bottom_right: {x: coal_params.coal_right_part[2].x * this.pixcel + addition_offest, y: coal_params.coal_right_part[2].y * this.pixcel + this.border_thickness},
          bottom_left: {x: coal_params.coal_right_part[3].x * this.pixcel, y: coal_params.coal_right_part[3].y * this.pixcel + this.border_thickness},
        }

      this.left_part_points = `
          ${left_part.top_left.x}, ${left_part.top_left.y}
          ${left_part.top_right.x}, ${left_part.top_right.y}
          ${left_part.bottom_right.x}, ${left_part.bottom_right.y}
          ${left_part.bottom_left.x}, ${left_part.bottom_left.y}
      `

      this.right_part_points = `
          ${right_part.top_left.x}, ${right_part.top_left.y}
          ${right_part.top_right.x}, ${right_part.top_right.y}
          ${right_part.bottom_right.x}, ${right_part.bottom_right.y}
          ${right_part.bottom_left.x}, ${right_part.bottom_left.y}
      `

      this.line = {
        x1: left_part.top_right.x,
        y1: left_part.top_right.y,
        x2: right_part.bottom_left.x,
        y2: right_part.bottom_left.y,
      }

      svg.drag(move, start)

    },
  }
};
</script>
